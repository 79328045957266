import React from "react";
import { BlockUI } from "ns-react-block-ui";
import { useState, useEffect } from "react";
import Select from "react-select";
import { createRoot } from "react-dom/client";

export default function ProductFilter() {
    const [blocking, setBlocking] = useState(false);

    const url = "https://kzrepuestos.kintierp.com/api/store";
    const headers = {
        "Content-type": "application/json",
        "x-api-key": "zKEEmDQg.cAimIlDT1nVkVSex87q6kVwegcYKHmwt",
    };

    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);

    useEffect(() => {
        setBlocking(true);
        try {
            fetch(`${url}/brands/get_brands/?type=1`, {
                headers,
            })
                .then((response) => response.json())
                .then((json) => {
                    let data = [];
                    json.forEach(e => data.push({ value: e.id, label: e.name }));
                    setBrands(data);
                    setBlocking(false);
                });
        } catch (err) {
            console.log(err);
            setBlocking(false);
        }
    }, []);

    const handleChangeBrand = async (e) => {
        setSelectedBrand(e);
    };

    const handleClick = async (e) => {
        e.preventDefault();
        let baseUrl = "productos?";
        window.location.href = baseUrl;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let baseUrl = "productos?";
        let { code, alt_code, name, description } = document.forms[0];

        if (code.value) {
            baseUrl += `codigo=${code.value}&`;
        }

        if (alt_code.value) {
            baseUrl += `alterno=${alt_code.value}&`;
        }

        if (selectedBrand) {
            baseUrl += `marca=${selectedBrand.value}&`;
        }

        if (name.value) {
            baseUrl += `nombre=${name.value}&`;
        }

        if (description.value) {
            baseUrl += `descripcion=${description.value}`;
        }

        window.location.href = baseUrl;
    };

    return (
        <BlockUI
            blocking={blocking}
            loader="default"
            mode="stretch"
            cursor="wait"
            overlayStyle={{
                background: "#F3F4F6",
                opacity: 0.5,
            }}
        >
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-10">
                    <div className="row">

                        <div className="col-3 mb-3">
                            <input type="text" className="form-control" placeholder="Código principal" name="code" />
                        </div>
                        <div className="col-3">
                            <input type="text" className="form-control" placeholder="Código alterno" name="alt_code" />
                        </div>
                        <div className="col-3">
                            <input type="text" className="form-control" placeholder="Nombre" name="name" />
                        </div>
                        <div className="col-3 ">
                            <Select
                                value={selectedBrand}
                                onChange={(e) => handleChangeBrand(e)}
                                options={brands}
                                placeholder="Marca"
                            />
                        </div>
                        <div className="col-6">
                            <input type="text" className="form-control" placeholder="Descripción" name="description" />
                        </div>

                    </div>
                </div>
                <div className="col-2">
                    <div className="d-flex justify-content-end search-buttons">
                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(e) => handleClick(e)}>Limpiar</button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            <span className="mdi mdi-magnify"></span> Buscar
                        </button>
                    </div>
                </div>
            </form>

        </BlockUI>
    );
}

if (document.getElementById("product-filter")) {
    const component = createRoot(document.getElementById("product-filter"));
    component.render(<ProductFilter />);
}
